const Widget2 = () => {
  return (
    <coingecko-coin-price-static-headline-widget 
    coin-ids="bitcoin,ethereum,eos,ripple,litecoin" 
    currency="usd" 
    locale="en"
    ></coingecko-coin-price-static-headline-widget>
  );
};

export default Widget2;
