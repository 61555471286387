import { Link } from "react-router-dom";
import logo from "../../images/logoshield.png";
import { FaTrademark } from "react-icons/fa";
const WhiteIcon = () => {
  return (
    <>
      <Link
        to="/"
        className="rounded-md w-32 pointer py-2 relative px- flex justify-center text-black updpercase m-3 font-bold items-center text-[18px]"
      >
        <div className="relative flex">
          <img src={logo} alt="trademark" />

          <span className="absolute left-9 top-2 font-playfair text-black">iSecureshield</span>
          {/* <span className="absolute bottom-[1.95rem] right-7 text-xs font-playfair">hield</span> */}
        </div>
       
      </Link>
    </>
  )
};

export default WhiteIcon;
